<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">
            Coupon
          </h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Coupon</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6">
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                    <!-- data-bs-toggle="modal"
                    data-bs-target="#modals-slide-in" -->
                    <div class="float-end col-6">
                      <button
                        type="button"
                        class="btn btn-gradient-primary float-end"
                        @click="add"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                <td>{{ index + paginationData.from }}</td>
                                <td><img class="avatar " :src="li.photo" alt="Photo" height="55" width="55" /></td>
                                <td>{{ li.name }}</td>
                                <td>{{ li.code }}</td>
                                <td>{{ li.amount }}</td>
                                <td>{{ li.validity_from }}</td>
                                <td>{{ li.validity_to }}</td>
                                <td>{{ li.created_at }}</td>
                                <td>{{ li.updated_at }}</td>
                                <td class="d-flex">
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="edit(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="edit" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteDes(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="trash-2" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="12" class="text-center">No Data Found</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <teleport to="#modal">
    <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
      <div class="modal-dialog">
        <form
          class="add-new-user modal-content pt-0"
          @submit.prevent="submitForm"
          @keydown="form.onKeydown($event)"
          enctype="multipart/form-data"
        >
          <input type="hidden" name="id" :v-modal="form.id" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="[isShow='', isDisplay='none']"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ modalName }} Coupon
            </h4>
          </div>
          <div class="modal-body flex-grow-1">

            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Name</label>
              <input
                type="text" class="form-control" name="name" v-model="form.name" placeholder="Name" @keyup="
                  form.name.length > 0
                    ? [(nameError = 'none'), (this.disabled = false)]
                    : (nameError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: nameError}" > Please enter name. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Code</label>
              <input
                type="text" class="form-control" name="code" v-model="form.code" placeholder="Code" @keyup="
                  form.code.length > 0
                    ? [(codeError = 'none'), (this.disabled = false)]
                    : (codeError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: codeError}" > Please enter Code. </div>
            </div>
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name">Amount</label>
              <input
                type="number" class="form-control" name="amount" v-model="form.amount" id="amount" placeholder="Amount" @keyup="
                  form.amount.toString().length > 0
                    ? [(amountError = 'none'), (this.disabled = false)]
                    : (amountError = 'block')
                "/>
              <div class="invalid-feedback fw-bold fst-italic" :style="{display: amountError}" > Please enter Mobile. </div>
            </div>
            <div class="mb-1">
              <div class="row">
                <div class="col-6">
                  <label class="form-label" for="basic-addon-name">Validity From</label>
                  <Datepicker 
                    type="date"
                    v-model="form.validity_from" 
                    format="dd-MM-yyyy"
                    value-format="dd-MM-yyyy"
                    :min-date='new Date()'
                  />
                </div>
                <div class="col-6">
                  <label class="form-label" for="basic-addon-name">Validity To</label>
                  <Datepicker 
                    type="date"
                    v-model="form.validity_to" 
                    format="dd-MM-yyyy"
                    value-format="dd-MM-yyyy"
                    :min-date='new Date()'
                  />
                </div>
                <div class="invalid-feedback fw-bold fst-italic" :style="{display: validityError}" > Please enter Coupon Date. </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-10">
                  <label class="form-label" for="basic-addon-name">Photo</label>
                  <input type="file" ref="file" class="form-control" name="photo" placeholder="Photo" @change="uploadImage" />
              </div>
              <div class="col-2">
                <div id="user-avatar-section">
                  <div id="d-flex justify-content-start">
                    <img v-if="url" :src="url" class="img-fluid rounded" height="104" width="104"/>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary me-1 data-submit"
              :disabled="disabled"
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              :disabled="disabled"
              data-bs-dismiss="modal"
              @click="[isShow='', isDisplay='none']"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
import Form from 'vform';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'

export default {
  name: "Employee",
  components: {
    Pagination: LaravelVuePagination,
    Datepicker
  },
  data() {
    return {
      form: new Form({
        id: null,
        name: "",
        code: "",
        amount: "",
        validity_from: null,
        validity_to: null,
        photo: "",
      }),
      url: null,
      response: "",
      disabled: false,
      header: [],
      list: [],
      modalName: "Add",
      paginationData: {},
      search: null,
      nameError: "none",
      codeError: "none",
      amountError: "none",
      validityError: "none",
      isShow: "",
      isDisplay: "none",
      token: localStorage.getItem('auth_token')
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Photo",
      "Name",
      "Code",
      "Amount",
      "Validity From",
      "Validity To",
      "Created",
      "Updated",
      "Action",
    ];
    this.getResults();
  },
  methods: {
    async add() {
      (this.form.id = null),
      (this.form.name = ""),
      (this.form.code = ""),
      (this.form.amount = ""),
      (this.form.validity_from = ""),
      (this.form.validity_to = ""),
      (this.form.photo = ""),
      (this.response = "");
      this.$refs.file.value = "";
      this.url = "";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async getResults(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_URL+"/coupon?page=" + page, 
        {
          headers: {
            "Content-Type": "application/json",
            'Accept': "application/json",
            "Authorization": `Bearer ${this.token}`
          }
        })
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    uploadImage(e) {
        let img = e.target.files[0]
        this.form.photo = img;
        this.url = URL.createObjectURL(img);
    },
    async submitForm() {
      this.disabled = true;
      if (this.form.name.length === 0) {
        this.nameError = "block"
      } else if (this.form.code.length === 0) {
        this.codeError = "block"
      } else if (this.form.amount.toString().length === 0) {
        this.amountError = "block"
      } else {

        this.form.validity_from = moment(this.form.validity_from).format('YYYY-MM-DD')
        this.form.validity_to = moment(this.form.validity_to).format('YYYY-MM-DD')

        var apiUrl = '';
        if (this.form.id) {
          apiUrl = process.env.VUE_APP_API_URL+"/coupon/update/" + this.form.id;
        } else {
          apiUrl = process.env.VUE_APP_API_URL+"/coupon";
        }

        await this.form.post(apiUrl,
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          })
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message);
              this.isShow = ""
              this.isDisplay = "none"
              this.disabled = false;
              this.getResults();
            } else {
              this.$toast.error(response.data.error?.[0]);
              this.disabled = false;
            }
          })
          .catch((error) => {
            this.$toast.error(error);
            this.disabled = false;
          });
      }
    },
    deleteDes(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL+"/coupon/" + id,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then(response => {
              this.getResults();
              if (response.data.status) {
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async edit(id) {
      this.nameError= "none"
      this.codeError= "none"
      this.amountError= "none"
      this.disabled= false

      const output = await axios.get(process.env.VUE_APP_API_URL+"/coupon" +(id ? "/" + id : ""),
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              });
              console.log(output);
      this.form.id = output.data.data?.id;
      this.form.name = output.data.data?.name;
      this.form.code = output.data.data?.code;
      this.form.amount = output.data.data?.amount;
      this.form.validity_from = output.data.data?.validity_from;
      this.form.validity_to = output.data.data?.validity_to;
      this.url = output.data.data?.photo

      this.modalName = "Edit";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async searchData() {
      await axios
        .post(process.env.VUE_APP_API_URL+"/coupon/search",
          {
            search: this.search,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data.data;
            this.isShow = ""
            this.isDisplay = "none"
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
  },
};
</script>
